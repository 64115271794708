import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import {createMarkup} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";

import CarouselWrapper from '../../../components/carousel/CarouselWrapper';
import Project01 from './images/project/05-2-project-01a.jpg';
import Project02 from './images/project/05-2-project-02a.jpg';
import Project03 from './images/project/05-2-project-03a.jpg';
import Project04 from './images/project/05-2-project-04a.jpg';
import Project05 from './images/project/05-2-project-05a.jpg';
import Project06 from './images/project/05-2-project-06a.jpg';
import Project07 from './images/project/05-2-project-07a.jpg';
import Project08 from './images/project/05-2-project-08a.jpg';
import athFac from './images/applications/ath-fac.jpg';
import libStorage from './images/applications/lib-storage.jpg';
import juiceBar from './images/applications/juice.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-education.jpg";

const IndexPage = () => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const projectImages = [
    {
      original: Project01,
      originalAlt: 'Day Use Lockers Secured with Cue by Digilock at Mount Royal State University',
      thumbnailAlt: 'Day Use Lockers Secured with Cue by Digilock at Mount Royal State University thumbnail',
      thumbnail: Project01,
      thumbnailLabel: 'Mount Royal University',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project02,
      originalAlt: 'Iowa State University Athletic Lockers Secured by Cue Electronic Locks',
      thumbnailAlt: 'Iowa State University Athletic Lockers Secured by Cue Electronic Locks thumbnail',
      thumbnail: Project02,
      thumbnailLabel: 'Iowa State University',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project03,
      originalAlt: 'Ohio State University keypad lockers',
      thumbnailAlt: 'Ohio State University keypad lockers thumbnail',
      thumbnail: Project03,
      thumbnailLabel: 'Ohio State University',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project04,
      originalAlt: 'University of Texas keypad lockers',
      thumbnailAlt: 'University of Texas keypad lockers thumbnail',
      thumbnail: Project04,
      thumbnailLabel: 'University of Texas',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project05,
      originalAlt: 'Alfa College keypad lockers',
      thumbnailAlt: 'Alfa College keypad lockers thumbnail',
      thumbnail: Project05,
      thumbnailLabel: 'Alfa College',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project06,
      originalAlt: 'Colorado College keypad lockers',
      thumbnailAlt: 'Colorado College keypad lockers thumbnail',
      thumbnail: Project06,
      thumbnailLabel: 'Colorado College',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project07,
      originalAlt: 'Loyola University keypad lockers',
      thumbnailAlt: 'Loyola University keypad lockers thumbnail',
      thumbnail: Project07,
      thumbnailLabel: 'Loyola University',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project08,
      originalAlt: 'Cue by Digilock in Princeton University Student Locker Room',
      thumbnailAlt: 'Cue by Digilock in Princeton University Student Locker Room thumbnail',
      thumbnail: Project08,
      thumbnailLabel: 'Princeton University',
      productLink: returnLocaleURL(sl, '/products/')

    }
  ];
  const projectAsides = [
    '<span>canada</span><br>' +
    '<h2>Mount Royal University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Iowa State University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Ohio State University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>University of Texas</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>Malaysia</span><br>' +
    '<h2>Alfa College</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa Mini Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Colorado College</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Loyola University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Princeton University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>'

  ];
  const ProjectAside = _ => <aside className={'dynamic-aside'}
                                   dangerouslySetInnerHTML={createMarkup(projectAsides[0])} />;
  const solutions = [
    {
      img: athFac,
      label: 'athletic_facilities',
      alt: 'athletic_facilities',
      bg: 'dark '
    },
    {
      img: libStorage,
      label: 'library_storage',
      alt: 'library_storage',
      bg: 'light'
    },
    {
      img: juiceBar,
      label: 'juicebar_charging_lockers',
      alt: 'juicebar_charging_lockers',
      bg: 'light'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_education')}
        description={t('des_solutions_education')}/>
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-education.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Education"
            />
            <h1>
              <Trans>
                education
              </Trans>
              <span>
                <Trans>education_hero_subhead</Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>education_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>education_subhero_list_l1</Trans></li>
                  <li><Trans>education_subhero_list_l2</Trans></li>
                  <li><Trans>education_subhero_list_l3</Trans></li>
                  <li><Trans>education_subhero_list_l4</Trans></li>
                  <li><Trans>education_subhero_list_l5</Trans></li>
                  <li><Trans>education_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/education.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="education"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup label={'education_lineup_label'} showMech={false} showPivot={true} showCurve={true} showAspire={true} showVersa={true} showCTA={true} />
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>education_carousel</Trans></h2>
              <CarouselWrapper
                  items={projectImages}
                  asides={projectAsides}
                  projectAside={<ProjectAside />}
                  showThumbnails={true}
                  showBullets={true}
                  dynamicAside={true}
                  allowImageClick={false}
              />
            </div>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterSolution}
                title={'education_customer_video_title'}
                copy={'education_customer_video_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/N2teqf7R8kauunHroklILg/iQhCqmYu3k-vmJ89jIOXhg/Widescreen720p/education.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
